<template>
    <div class="text-xs ticket">
        <div class="no-print" style="position:absolute;right:50px;top:10px;">
            <button type="button" @click="prints" class="btn btn-sm btn-dark mr-3 ">Print <span class="typcn typcn-print"></span></button>
            <button type="button" @click="$router.push('/refresh')" class="btn btn-sm btn-dark  my-3">Back to Dashboard</button>
            <hr>
        </div>
        <img src="@/static/logonya.jpg" style="width:80px;height:80px;display:block;margin:auto;" alt="">
        <div >
            <p class="text-center font-semibold" style="font-size:25px;">{{datatoko.nama_toko}}</p>
            <p class="text-center font-semibold" style="font-size:20px;">{{datatoko.alamat}}</p>
        <br>
            <p class="float-right">
              {{format(datanya[0].tanggal)}}
            </p>
            <p>{{datanya[0].nota}}</p>
          <div >
              <table  border='1'>
                  <thead>
                    <tr >
                        <td>Item</td>
                        <td>Jumlah</td>
                        <td>Harga</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="(item, index) in transaksi" :key="index+'transaksi'">
                        <td class="description">{{item.nama_produk}}</td>
                        <td class="quantity">{{item.jumlah}} x Rp.{{item.harga}} </td>
                        <td class="price">Rp.{{item.total}} ({{item.diskon}}%)</td>
                    </tr>
                    <tr >
                        <td class="description"></td>
                        <td class="quantity">Harga Total</td>
                        <td class="price">Rp.{{datanya[0].total}}</td>
                    </tr>
                    <tr >
                        <td class="description"></td>
                        <td class="quantity">Uang Dibayar</td>
                        <td class="price">Rp.{{datanya[0].uang}}</td>
                    </tr>
                    <tr >
                        <td></td>
                        <td>Kembalian</td>
                        <td>Rp.{{parseInt(datanya[0].uang)-parseInt(datanya[0].total)}}</td>
                    </tr>
                  </tbody>
              </table>
          </div>
        </div>
        <br>
        <p class="text-right">
            Terima Kasih 
        </p>
        <br>
    </div>    
</template>
<script>
import axios from 'axios'
import autophp from '@/plugins/autophp_kasir'
let sdb = new autophp();
export default{
    data(){
        return{
            number:6,
            total:0,
            uang:0,
            transaksi:[],
            position:'center',
            datatoko:[],
            datanya:[],
            search:'',
            notas:[],
            id_nota:0
        }
    },
     computed:{
      td(){
          let data=this.notas;
            data=data.filter(e=>{
                if(e.nota.toLowerCase().indexOf(this.search)!=-1){
                    return e
                }
            })
            return data
        }
    },
    methods: {
        format(tanggal) {
            console.log(tanggal)
              return this.$datefns.format(
                new Date(tanggal),
                "DD MMMM YYYY/HH-mm"
              );
            },
        prints(){
            window.print();
        },
        formatRupiah(angka, prefix){
			var number_string = angka.replace(/[^,\d]/g, '').toString(),
			split   		= number_string.split(','),
			sisa     		= split[0].length % 3,
			rupiah     		= split[0].substr(0, sisa),
			ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
 
			// tambahkan titik jika yang di input sudah menjadi angka ribuan
			if(ribuan){
				separator = sisa ? '.' : '';
				rupiah += separator + ribuan.join('.');
			}
 
			rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
			return prefix == undefined ? rupiah : (rupiah ? 'Rp.' + rupiah : '');
		},
        ambilnota(item){
            this.$route.query.id=item.id;
            this.getData();
        },
         getNota(){
          sdb.collection("app_kasir_transaksi_nota").doc().get("*","order by id").then(res => {
            this.notas=res;
              this.$forceUpdate();
          });
      },
      getToko(){
           sdb.collection("app_kasir_toko",false).doc().select(`select * from app_kasir_toko`).then(res => {
            this.datatoko=res[0]
            this.$forceUpdate();
          });
      },
        getData(){
            sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT tb3.nama_produk,tb2.harga,tb4.satuan,tb2.total,tb2.jumlah,tb2.diskon FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota left join app_kasir_produk tb3 on tb3.id=tb2.id_produk left join app_kasir_produk_satuan tb4 on tb4.id=tb2.satuan where tb1.nota='${this.$route.query.nota}' order by tb1.id`).then(res => {
                this.transaksi=res;
            });
            sdb.collection("app_kasir_transaksi_nota").doc().get("*",`where nota='${this.$route.query.nota}' order by id`).then(res => {
                this.datanya=res;
                // setTimeout(() => {
                //     window.print()
                // }, 1000);
            });
            
        }
    },
    mounted() {
        this.getData();
        this.getToko();
    },
}
</script>
<style scoped>
* {
    font-size: 18px;
    font-family: 'Times New Roman';
}

td,
th,
tr,
table {
    border-top: 1px solid black;
    border-collapse: collapse;
    width:420px;
}

td.description,
th.description {
    width: 75px;
    max-width: 75px;
}

td.quantity,
th.quantity {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

td.price,
th.price {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

.centered {
    text-align: center;
    align-content: center;
}

.ticket {
    width: 420px;
    max-width: 420px;
    padding:10px;
}

img {
    max-width: inherit;
    width: inherit;
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}

</style>